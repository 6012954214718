html, body {
	overflow: auto;
	height: 100%;
}

#__next {
	height: 100%;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 1rem 0;
}

.p-0 {
	padding: 0
}

.p-2 {
	padding: 2rem
}

.m-0 {
	margin: 0
}

.small-btn {
	margin: 0;
	padding: 0.25rem;
}

.red {
	color: #c62828;
}